.cards__wrapper {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.card-image {
  width: 300px;
  height: 200px;
  object-fit: fill;
}

.desc-text {
  color: black;
  font-family: "SF Pro Display";
  font-size: 1em;
  padding: 8px;
  width: 95%;
  margin: 0;
}

@media (min-width: 700px) {
  .cards__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  }

  .card-image {
    width: 100%;
    height: 280px;
    object-fit: contain;
  }

  .desc-text {
    font-size: 1.2rem;
    padding: 16px;
  }
}
