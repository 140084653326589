nav {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 56px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
}

.name {
  z-index: 999;
  position: fixed;
  top: 16px;
  right: 16px;
  font-family: "SF Pro Display";
  font-size: 1.2em;
  margin: 0;
}

.linkedin-img {
  position: fixed;
  top: 16px;
  left: 16px;
  z-index: 999;
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.github-img {
  position: fixed;
  top: 16px;
  left: 48px;
  z-index: 999;
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.uxfolio-text {
  display: none;
}

.uxfolio-img {
  position: fixed;
  top: 10px;
  left: 80px;
  z-index: 999;
  width: 36px;
  height: 36px;
  object-fit: contain;
}

@media (min-width: 699px) {
  .uxfolio-text {
    display: inline;
    z-index: 999;
    position: fixed;
    top: 16px;
    left: 96px;
    font-family: "SF Pro Display";
    font-size: 1.2em;
    margin: 0;
  }

  .uxfolio-img {
    position: fixed;
    top: 8px;
    left: 340px;
    z-index: 999;
    width: 36px;
    height: 36px;
    object-fit: contain;
  }

  a:hover {
    text-decoration: underline;
  }
}

@media (min-width: 1025px) {
  nav {
    height: 5rem;
  }
  .linkedin-img {
    top: 24px;
    left: 32px;
    width: 40px;
    height: 40px;
  }

  .github-img {
    top: 24px;
    left: 96px;
    width: 40px;
    height: 40px;
    margin-right: 12px;
  }

  .name {
    font-size: 1.4rem;
    top: 24px;
    right: 32px;
  }

  .uxfolio-text {
    z-index: 999;
    position: fixed;
    top: 32px;
    left: 164px;
    font-family: "SF Pro Display";
    font-size: 1.4rem;
    margin: 0;
  }

  .uxfolio-img {
    position: fixed;
    top: 24px;
    left: 450px;
    z-index: 999;
    width: 48px;
    height: 48px;
    object-fit: contain;
  }
}
