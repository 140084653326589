@font-face {
  font-family: "New York";
  src: url("./font/NewYorkMedium-Medium.otf");
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./font/SF-Pro-Display-Ultralight.otf");
}

@font-face {
  font-family: "SF Pro Display Bold";
  src: url("./font/SF-Pro-Display-Bold.otf");
}

body {
  color: #000000;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero {
  width: 100%;
  height: 77vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("./img/hero-image3.jpg");
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.section-title {
  font-family: "SF Pro Display";
  font-size: 2rem;
  color: black;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  text-align: center;
}

.hero-text {
  font-family: "SF Pro Display";
  font-size: 1.7rem;
  color: white;
  width: 80%;
}

.desc-text {
  font-size: 1rem;
}

@media (min-width: 699px) {
  .hero {
    height: 92vh;
  }
}

@media (min-width: 1025px) {
  .hero {
    margin-bottom: 8rem;
    height: 70vh;
  }

  .section-title {
    width: 100%;
    text-align: center;
    font-family: "SF Pro Display";
    font-size: 4rem;
    color: black;
    margin-bottom: 2rem;
  }

  .linkedin-img:hover,
  .github-img:hover,
  .uxfolio-img:hover {
    opacity: 0.5;
    cursor: pointer;
  }

  /* .github-img:hover {
    opacity: 0.5;
    cursor: pointer;
  } */
  .about__container {
    height: 40%;
    width: 100%;
    background-image: url("./img/profile.jpeg");
    background-attachment: fixed;
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: contain;
    padding-bottom: 8rem;
  }
}
