.profile-image {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  align-self: center;
}
.title {
  font-family: "SF Pro Display";
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
  letter-spacing: 0.1rem;
  margin-bottom: 1rem;
  padding: 1rem;
}

@media (min-width: 750px) {
  .profile-image {
    width: 50%;
    height: auto;
    display: block;
    margin-left: auto;
  }

  .image__wrapper {
    width: 100%;
  }

  .title__wrapper {
    width: 100%;
  }

  .title {
    width: 100%;
    font-size: 2rem;
  }
}

@media (min-width: 999px) {
  .profile-image {
    display: none;
  }
  .image__wrapper {
    width: 25%;
    padding: 1rem;
  }

  .title__wrapper {
    width: 100%;
  }

  .title {
    width: 100%;
    font-size: 2rem;
  }

  .profile-img {
    width: 100%;
    height: auto;
  }
}
